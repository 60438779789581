import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/homepages-banner",
    name: "HomepagesBanner",
    component: () => import("@/views/Homepages/HomepagesBanner.vue"),
  },
  {
    path: "/homepages-meta",
    name: "HomepagesMeta",
    component: () => import("@/views/Homepages/HomepagesMeta.vue"),
  },
  {
    path: "/homepages-section-2",
    name: "HomepagesSection2",
    component: () => import("@/views/Homepages/HomepagesSection2.vue"),
  },
  {
    path: "/homepages-section-3",
    name: "HomepagesSection3",
    component: () => import("@/views/Homepages/HomepagesSection3.vue"),
  },
  {
    path: "/homepages-section-4",
    name: "HomepagesSection4",
    component: () => import("@/views/Homepages/HomepagesSection4.vue"),
  },
  {
    path: "/homepages-section-5",
    name: "HomepagesSection5",
    component: () => import("@/views/Homepages/HomepagesSection5.vue"),
  },
  {
    path: "/product-meta",
    name: "ProductMeta",
    component: () => import("@/views/Product/ProductMeta.vue"),
  },
  {
    path: "/product-banner",
    name: "ProductBanner",
    component: () => import("@/views/Product/ProductBanner.vue"),
  },
  {
    path: "/asksenka-meta",
    name: "AskSenkaMeta",
    component: () => import("@/views/AskSenka/AskSenkaMeta.vue"),
  },
  {
    path: "/asksenka-banner",
    name: "AskSenkaBanner",
    component: () => import("@/views/AskSenka/AskSenkaBanner.vue"),
  },
  {
    path: "/senkastories-meta",
    name: "SenkaStoriesMeta",
    component: () => import("@/views/SenkaStories/SenkaStoriesMeta.vue"),
  },
  {
    path: "/senkastories-banner",
    name: "SenkaStoriesBanner",
    component: () => import("@/views/SenkaStories/SenkaStoriesBanner.vue"),
  },
  {
    path: "/about-meta",
    name: "AboutMeta",
    component: () => import("@/views/About/AboutMeta.vue"),
  },
  {
    path: "/about-banner",
    name: "AboutBanner",
    component: () => import("@/views/About/AboutBanner.vue"),
  },
  {
    path: "/about-content",
    name: "AboutContent",
    component: () => import("@/views/About/AboutContent.vue"),
  },
  {
    path: "/contact-meta",
    name: "ContactMeta",
    component: () => import("@/views/Contact/ContactMeta.vue"),
  },
  {
    path: "/contact-banner",
    name: "ContactBanner",
    component: () => import("@/views/Contact/ContactBanner.vue"),
  },
  {
    path: "/subscriber-banner",
    name: "SubscriberBanner",
    component: () => import("@/views/Subscriber/SubscriberBanner.vue"),
  },
  {
    path: "/contact-us",
    name: "ContactSubmission",
    component: () => import("@/views/Contact/ContactSubmission.vue"),
  },
  {
    path: "/subscriber",
    name: "SubscriberSubmission",
    component: () => import("@/views/Subscriber/SubscriberSubmission.vue"),
  },
  {
    path: "/asksenka/jenis-kulit",
    name: "JenisKulit",
    component: () => import("@/views/AskSenkaData/JenisKulit.vue"),
  },
  {
    path: "/asksenka/masalah-kulit",
    name: "MasalahKulit",
    component: () => import("@/views/AskSenkaData/MasalahKulit.vue"),
  },
  {
    path: "/asksenka/senka-tips",
    name: "SkinTips",
    component: () => import("@/views/AskSenkaData/SkinTips.vue"),
  },
  {
    path: "/asksenka/senka-tips/:id/suggestion",
    name: "SkinTipsSuggest",
    component: () => import("@/views/AskSenkaData/SkinTipsSuggest.vue"),
  },
  {
    path: "/asksenka/senka-tips/:id/product",
    name: "SkinTipsProduct",
    component: () => import("@/views/AskSenkaData/SkinTipsProduct.vue"),
  },
  {
    path: "/product/category",
    name: "ProductCategory",
    component: () => import("@/views/ProductData/ProductCategory.vue"),
  },
  {
    path: "/product",
    name: "Product",
    component: () => import("@/views/ProductData/Product.vue"),
  },
  {
    path: "/product/add",
    name: "ProductAdd",
    component: () => import("@/views/ProductData/ProductAdd.vue"),
  },
  {
    path: "/product/:id/edit",
    name: "ProductEdit",
    component: () => import("@/views/ProductData/ProductAdd.vue"),
  },
  {
    path: "/product/:id/about",
    name: "ProductAbout",
    component: () => import("@/views/ProductData/AboutAdd.vue"),
  },
  {
    path: "/product/:id/technology",
    name: "ProductTech",
    component: () => import("@/views/ProductData/TechnologyAdd.vue"),
  },
  {
    path: "/product/:id/whatsinit",
    name: "ProductWhat",
    component: () => import("@/views/ProductData/WhatsinAdd.vue"),
  },
  {
    path: "/product/:id/goodtoknow",
    name: "ProductGTK",
    component: () => import("@/views/ProductData/GoodtoKnowAdd.vue"),
  },
  {
    path: "/product/:id/similar",
    name: "ProductSimilar",
    component: () => import("@/views/ProductData/ProductSimilar.vue"),
  },
  {
    path: "/product/:id/packaging",
    name: "ProductPackaging",
    component: () => import("@/views/ProductData/ProductPackaging.vue"),
  },
  {
    path: "/product/:id/banner",
    name: "ProductBanners",
    component: () => import("@/views/ProductData/ProductBanner.vue"),
  },
  {
    path: "/product/:id/store",
    name: "ProductStore",
    component: () => import("@/views/ProductData/ProductStore.vue"),
  },
  {
    path: "/product/:id/review",
    name: "ProductReview",
    component: () => import("@/views/ProductData/ProductReview.vue"),
  },
  {
    path: "/article",
    name: "Article",
    component: () => import("@/views/Article/Article.vue"),
  },
  {
    path: "/article/add",
    name: "ArticleAdd",
    component: () => import("@/views/Article/ArticleAdd.vue"),
  },
  {
    path: "/article/:id/edit",
    name: "ArticleEdit",
    component: () => import("@/views/Article/ArticleAdd.vue"),
  },
  {
    path: "/article/:id/product",
    name: "ArticleProduct",
    component: () => import("@/views/Article/ArticleProduct.vue"),
  },
  {
    path: "/article/:id/glosarium",
    name: "ArticleGlosarium",
    component: () => import("@/views/Article/ArticleGlosarium.vue"),
  },
  {
    path: "/gallery",
    name: "Gallery",
    component: () => import("@/views/Gallery/Gallery.vue"),
  },
  {
    path: "/campaign/new-user",
    name: "Campaign - New User",
    component: () => import("@/views/Campaign/NewUser/CampaignList.vue"),
  },
  {
    path: "/campaign/new-user/add",
    name: "Add Campaign - New User",
    component: () => import("@/views/Campaign/NewUser/NewCampaign.vue"),
  },
  {
    path: "/campaign/new-user/:id",
    name: "Submissions - New User",
    component: () => import("@/views/Campaign/NewUser/Submission.vue"),
  },
  {
    path: "/campaign/returning",
    name: "Campaign - Returning User",
    component: () => import("@/views/Campaign/ReturningUser/CampaignList.vue"),
  },
  {
    path: "/campaign/returning/add",
    name: "Add Campaign - Returning User",
    component: () => import("@/views/Campaign/ReturningUser/NewCampaign.vue"),
  },
  {
    path: "/campaign/returning/:id",
    name: "Submissions - Returning User",
    component: () => import("@/views/Campaign/ReturningUser/Submission.vue"),
  },
  {
    path: "/campaign/marketplaces",
    name: "Campaign - Marketplaces",
    component: () => import("@/views/Campaign/Marketplace/index.vue"),
  },
  {
    path: "/campaign/marketplaces/add",
    name: "Add Campaign - Marketplaces",
    component: () => import("@/views/Campaign/Marketplace/create.vue"),
  },
  {
    path: "/campaign/marketplaces/:id",
    name: "Submissions - Marketplaces",
    component: () => import("@/views/Campaign/Marketplace/edit.vue"),
  },
  {
    path: "/campaign/type",
    name: "Campaign - Marketplaces",
    component: () => import("@/views/Campaign/Type/index.vue"),
  },
  {
    path: "/campaign/type/add",
    name: "Add Campaign - Marketplaces",
    component: () => import("@/views/Campaign/Type/create.vue"),
  },
  {
    path: "/campaign/type/:id",
    name: "Submissions - Marketplaces",
    component: () => import("@/views/Campaign/Type/edit.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
