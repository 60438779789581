import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueRouter from "vue-router";
import vuetify from "./plugins/vuetify";
import VueLazyload from "vue-lazyload";
import CKEditor from "ckeditor4-vue";
import "@mdi/font/css/materialdesignicons.css";
import "@/assets/css/grid.css";
import "@/assets/css/common.css";
import axios from "axios";
// import CKEditor from 'ckeditor4-vue/dist/legacy.js'

// var baseURL = "http://localhost:3000";
// var baseURL = "http://54.254.132.16:3000";
var baseURL = 'https://backend.senka.id';
axios.defaults.baseURL = baseURL;
axios.defaults.headers["Content-Type"] = "application/json";

// CKEditor.editorConfig = function( config ) {
//   config.filebrowserBrowseUrl = '/browser/browse.php';
// };

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(VueLazyload);
Vue.use(CKEditor);
Vue.use(require("vue-moment"));

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: "dist/error.png",
  loading: "dist/loading.gif",
  attempt: 1,
});

new Vue({
  vuetify,
  router,
  data: {
    admin: null,
    baseURL: baseURL,
    adminfullname: "",
    tabs: "",
    icons: {
      iconfont: "mdi", // default - only for display purposes
    },
    token: function (key) {
      if (key === false) {
        window.localStorage.removeItem("token");
        axios.defaults.headers["Authorization"] = `Bearer`;
        return false;
      }
      if (typeof key != "undefined") {
        window.localStorage.setItem("token", key);
        axios.defaults.headers["Authorization"] = `Bearer ${this.token()}`;
        return key;
      }
      var v = window.localStorage.getItem("token");
      if (!v || v === "" || v === "false") {
        axios.defaults.headers["Authorization"] = `Bearer`;
        return false;
      }
      return v;
    },
    refreshToken: function (key) {
      if (key === false) {
        window.localStorage.removeItem("refreshToken");
        return false;
      }
      if (typeof key != "undefined") {
        window.localStorage.setItem("refreshToken", key);
        return key;
      }
      var v = window.localStorage.getItem("refreshToken");
      if (!v || v === "" || v === "false") {
        return false;
      }
      return v;
    },
    axios: axios,
    upload: function (method, url, form) {
      return this.axios({
        method: method,
        url: url,
        data: form,
        headers: {
          "Content-Type": "multipart/form-data",
          "Content-Type": "application/json",
        },
      });
    },
  },
  created() {
    axios.defaults.headers["Authorization"] = `Bearer ${this.token()}`;
  },
  render: (h) => h(App),
}).$mount("#app");
